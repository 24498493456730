.cm-filter {
    width: 100%;
    height: calc(100% - 60px);
    font-size: 14px;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    transition: height 0.5s;

    .cm-select {
        width: 100%;
    }

    &.closed {
        height: 0;
    }

    /* antd overwrite */
    .ant-select-selection--multiple {
        .ant-select-selection__rendered > ul > li {
            line-height: 18px;
        }
        .ant-select-selection__choice {
            padding-left: 5px;
            line-height: 18px;
            .ant-select-selected-icon {
                display: none;
            }
        }
        .ant-select-selection__choice__content {
            margin-top: 2px;
        }
    }

    .anticon {
        &.anticon-caret-down {
            vertical-align: middle !important;
        }
        line-height: 0 !important;        
    }
    
    .ant-select-tree {
        margin: 4px 0 !important;
        padding: 0 !important;
        li {        
            white-space: normal !important;
            margin: 0 !important;

            .ant-select-tree-checkbox {
                top: 0;
            }

            .ant-select-tree-node-content-wrapper {
                vertical-align: middle;
                padding: 5px 0 5px 5px !important;

                &.ant-select-tree-node-selected {
                    color: rgba(0, 0, 0, 0.65);
                    font-weight: 600;
                    background-color: #fafafa;
                }
                &:hover {
                    background-color: #e6f7ff;
                    .ant-select-selected-icon {
                        color: black;
                    }
                }
            }
            .ant-select-tree-title {
                display: inline-block;
                padding-right: 5px;
                position: relative;
            }
        }
    }

    .ant-tabs {
        color: inherit;
    }
    
    &.bg-dark {
        .jumbotron {
            background-color: rgba(255, 255, 255, 0.1);
        }
        .ant-select-selection, .ant-input {        
            background-color: #343a40;
            color: white;
        }
        .ant-calendar-picker-icon, .ant-calendar-range-picker-separator {
            color: rgba(255, 255, 255, 0.45);
        }
        .ant-calendar-picker-clear {
            color: rgba(255, 255, 255, 0.45);
            background: #343a40;
        }
    }    
}

@media (min-width: 768px) {
    .cm-filter {
        width: 420px;
        height: 100%;
        position: relative;
        &.closed {
            height: 100%;
        }
    }
}