@import "../../styles/_vars";

.cm-alert {
    &.ant-alert {
        border-radius: 0;
        border: none;
        font-size: 12px;
        background-color: #e9ecef !important;
    }

    &.bg-dark {
        background-color: #4a4e53 !important;
    }

    .ant-alert-message,
    .ant-alert-close-icon .anticon-close {
        color: inherit;
    }

    .anticon {
        vertical-align: 0.2em;
    }
}

.ant-modal {
    width: 90% !important;
}
