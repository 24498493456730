.cm-map {
    z-index: 1;
}
/*
.leaflet-pane {
    .leaflet-heatmap-layer {
        z-index: -1;
    }
}
*/

.cm-maploding {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;

    &.dark {
        background-color: rgba(0, 0, 0, .7);
    }
    &.light {
        background-color: rgba(255, 255, 255, .7);
    }

    svg {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        transform: translateX(-50%);
    }
}

.leaflet-control-layers-base {
    label {
        margin-bottom: 0;
    }
}

.cm-legend {
    padding: 8px 10px;
    line-height: 20px;
}
.cm-circle {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.7;
    vertical-align: -3px;

    &.dead {
        border: 3px solid black;
    }
}