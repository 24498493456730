@import '../../styles/_vars';

.react-toggle-track-check {
    width: 16px;
    height: 16px;
    left: 7px;
}

.react-toggle-track-x {
    width: 16px;
    height: 16px;
    right: 7px;
}

.react-toggle-track {
    background-color: #0f1114 !important;
}

.react-toggle--checked .react-toggle-thumb {
    border-color: #0f1114; //rgba( $red, .7 );
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: none; // 0 0 2px 3px rgba( $red, .7 )
}

.react-toggle:active .react-toggle-thumb {
    box-shadow: none; //0 0 5px 5px rgba( $red, .7 )
}