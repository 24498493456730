@import '_vars';

// Override default variables before the import
// $body-bg: #000;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
// Import Antd components
@import '~antd/dist/antd.css';

//@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap&subset=latin-ext');

::selection { background-color: $red; color: white; }

html, body {
    width: 100%;
    height: 100%;
}
/*
body {
    font-family: 'Open Sans', sans-serif;
}*/
a:hover {
    color: $red;
}

#root, .cm-app-container {
    height: 100vh;
}